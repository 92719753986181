import BaseModel from "./BaseModels/BaseModel";
import IApprovalStatus from "./BaseModels/IApprovalStatus";
import Client from "./Client";
import { ApprovalStatus } from "./enums/ApprovalStatus";
import { CriticalityEnum } from "./enums/CriticalityEnum";
import { ServiceResponse } from "./Service";

export default class AgreementRequest extends BaseModel implements IApprovalStatus {
    approval_comment?: string
    criticality?: CriticalityEnum
    clients?: Client[]
    description?: string
    services?: ServiceResponse[]
    status: ApprovalStatus = ApprovalStatus.UNKNOWN
}


export class AgreementResponse extends BaseModel implements IApprovalStatus {
    short_id?: number
    criticality?: CriticalityEnum
    approval_comment?: string
    client?: Client
    description?: string
    service?: ServiceResponse
    status: ApprovalStatus = ApprovalStatus.UNKNOWN
    status_changed_by?: string
    created_by?: string
    status_changed_time?: Date
    created_time?: Date
    client_ip_addresses?: string[]
    provision_status?: string
    provision_time?: Date
    force_provision?: boolean
}

