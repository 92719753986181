import AddCircle from "@mui/icons-material/AddCircle";
import { Box, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { OrganisationBadge } from "../../components/Badges/OrganisationBadge";
import { CreateEntityButton } from "../../components/CreateEntityButton";
import EasyForm, { FormType } from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { TextRenderer } from "../../components/Forms/Renders/TextRenderer";
import { OrganisationContext } from "../../components/Layout/OrganisationPicker";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import BaseOrganisation, { CreateOrganisationAuthorization } from "../../models/Organisation";
import { Role, UserResponse, roleToString, getRolesUpTo } from "../../models/User";
import { PermissionEnum } from "../Permission/PermissionEnum";
import { AbilityContext, Can } from "../UserApi/logic/Can";
import { UserContext } from "../UserApi/logic/FetchUser";
import { useGetAllUserQuery } from "../UserApi/redux/userApiSlice";
import { AuthorisationDetailsContent } from "./authorisationInfoDialog/AuthorisationDetailsButton";
import { useAddAuthorizationMutation } from "./redux/authroisationApiSlice";


export const CreateAuthorisationButton = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [submittedAuthorisation, setSubmittedAuthorisation] = useState<CreateOrganisationAuthorization | undefined>(undefined)
    const organisationContext = useContext(OrganisationContext)?.organisation
    return (
        <Can I={PermissionEnum.ORGANISATION_WRITE} a={organisationContext} >
            <CreateEntityButton tooltip="Create authorisation" open={open} setOpen={setOpen} onClose={() => setSubmittedAuthorisation(undefined)} buttonIcon={<AddCircle />} >
                {submittedAuthorisation === undefined ?

                    <CreateAuthorisationButtonContent afterSaveAction={(authorisation) => {
                        setSubmittedAuthorisation(authorisation);
                    }} />
                    :
                    <AuthorisationDetailsContent authorisation_uuid={submittedAuthorisation.uuid!} afterDeleteAction={() => setOpen(false)} />
                }
            </CreateEntityButton>
        </Can >
    )
}

export interface CreateAuthorisationButtonContentProps {
    afterSaveAction: (authorisation?: CreateOrganisationAuthorization) => void
}
export const CreateAuthorisationButtonContent = (props: CreateAuthorisationButtonContentProps) => {
    const ability = useContext(AbilityContext);
    const { t } = useTranslation();
    const SignupSchema = Yup.object().shape({
        parentUser: Yup.object().nullable().required(t("Required")),
        role: Yup.string().nullable().required(t("Required"))
    });

    const userContext = useContext(UserContext);

    const possibleRoles = getRolesUpTo(userContext?.role)
    const organisationContext = useContext(OrganisationContext);
    const organisation = organisationContext?.organisation ?? userContext?.organisation
    const allUsersResult = useGetAllUserQuery({ pagination: new PaginationQueryPackage() });
    return (
        <>

            <Box>
                <Grid container justifyContent="center">

                    <Grid item xs={6}>
                        <Typography variant="h6">{t("Create authorisation") + ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>

                        <EasyForm
                            isAllowedToEdit={() => ability.can(PermissionEnum.ORGANISATION_WRITE, organisation)}
                            editModeAlways={true}
                            arrayPath=""
                            SignupSchema={SignupSchema}
                            createNew={() => {
                                const postBody = new CreateOrganisationAuthorization();
                                postBody.organisation = organisation;
                                return postBody;
                            }}
                            columns={[
                                new EasyFormColumn("", "", [
                                    new TextRenderer({
                                        displayText: "Organisation",
                                        fieldName: "organisation",
                                        uniqueId: "no_id",
                                        render: (content) => <OrganisationBadge ressource={content as BaseOrganisation} />
                                    }),
                                    new AutocompleteFieldRender({
                                        displayText: "User",
                                        fieldName: "parentUser",
                                        getOptionLabelFunc: (option: UserResponse) => option?.email === undefined ? "" : `${option.first_name} ${option.last_name} (${option.email})`,
                                        options: allUsersResult.data ?? [],

                                        uniqueId: crypto.randomUUID()
                                    }),
                                    new AutocompleteFieldRender({
                                        displayText: "Role",
                                        fieldName: "role",
                                        getOptionLabelFunc: (option: Role) => t(roleToString(option) ?? ""),
                                        options: possibleRoles,
                                        uniqueId: "no_id"
                                    })
                                ])
                            ]}
                            formType={FormType.INFORMATION}
                            idToFetch={"no_id"}
                            getFieldRows={() => []}
                            saveMutation={useAddAuthorizationMutation}
                            afterSaveAction={props.afterSaveAction}
                        />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}