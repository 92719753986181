import { History } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { GridColDef } from '@mui/x-data-grid-pro';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import ApprovalStatusChip from '../../components/ApprovalStatusChip';
import { ConfirmationButton } from '../../components/ConfirmationButton';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { DateToFormattedString } from '../../components/Logic/DateLogic';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import getEnvironment from '../../env';
import { AgreementResponse } from '../../models/Agreement';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import Client from '../../models/Client';
import { ApprovalStatus } from '../../models/enums/ApprovalStatus';
import { CriticalityEnum } from '../../models/enums/CriticalityEnum';
import { ServiceBase, ServiceResponse } from '../../models/Service';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext, Can } from '../UserApi/logic/Can';
import { AgreementInfoButton } from './agreementInfoDialog/AgreementInfoButton';
import { useDeleteAgreementMutation } from './redux/agreementsApiSlice';
import { CriticalityScoreBadge } from '../../components/Badges/CriticalityScoreBadge';

function getCurrentAgreement(agreements: AgreementResponse[], params: any) {
    return agreements.find(x => x.uuid === params.id)!;
}

class AgreementListProps {
    getAgreements!: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, AgreementResponse[], any>>
    idToFetch?: string
    statusList: ApprovalStatus[] = []
    defaultPagesize?: number
    simpleView?: boolean = false;
    dataGridId?: DataGridId
}


export const AgreementList = (props: AgreementListProps) => {
    return (<AgreementListContent {...props} />)
}

const AgreementListContent = (props: AgreementListProps) => {

    const [terminateAgreement] = useDeleteAgreementMutation();
    const currentResult = props.getAgreements({ pagination: new PaginationQueryPackage(), uuid: props.idToFetch, requestData: props.statusList })
    const [clickedRow, setClickedRow] = useState<AgreementResponse | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row
    const ability = useContext(AbilityContext);

    if (currentResult.data == undefined)
        currentResult.data = []

    const deleteAgreementButton = (agreement: AgreementResponse) => (
        ability.can(PermissionEnum.SERVICE_WRITE) || ability.can(PermissionEnum.CLIENT_WRITE) ?
            <ConfirmationButton variant="text" color="primary" title={t("Delete agreement")} buttonText={<DeleteOutlineIcon />} action={async () => {
                const toDelete = JSON.parse(JSON.stringify(agreement)) as AgreementResponse

                await terminateAgreement({ data: toDelete }).unwrap();
            }}>
                <>
                    {t("Are you sure you wish to delete this agreement?") + ""}
                </>
            </ConfirmationButton>
            : <></>
    )

    const getColumns = (agreements: AgreementResponse[]) => {


        const simpleView: GridColDef[] = [
            { field: 'short_id', sortable: true, type: "number", headerName: t('ID'), renderCell: (params: { row: AgreementResponse }) => <div>{params.row.short_id}</div>, flex: 2 },
            { field: 'description', sortable: true, type: "string", headerName: t('Description'), minWidth: 150, flex: 5 },
            { field: 'criticality', sortable: true, type: "singleSelect", valueOptions: Object.values(CriticalityEnum).map(e => t(e) + ""), headerName: t('Criticality'), valueGetter: (criticality) => t(criticality + ""), minWidth: 80, flex: 3 },
            { field: 'service', sortable: true, type: "string", headerName: t('Service'), valueGetter: (value: ServiceResponse) => value.name, minWidth: 200, flex: 4 },
            { field: 'client', sortable: true, type: "string", headerName: t('Client'), valueGetter: (client: Client) => client.name, flex: 4 },
        ];

        const advancedView: GridColDef[] = [

            { field: 'client_organisation', sortable: true, type: "string", headerName: t('Organisation'), valueGetter: (_, row: AgreementResponse) => row.client?.hosting_site?.owner_organisation?.name, flex: 4 },
            { field: 'status', sortable: true, type: "singleSelect", valueOptions: Object.values(ApprovalStatus).map(status => t(status) + ""), headerName: t('Status'), flex: 3, valueGetter: (status) => t(status + ""), renderCell: (params: { row: AgreementResponse }) => (<ApprovalStatusChip {...getCurrentAgreement(agreements, params)} />) },
            { field: 'created_time', sortable: true, type: "dateTime", headerName: t('Requested'), flex: 3, valueGetter: (createdTime) => createdTime && new Date(createdTime), renderCell: (params: { row: AgreementResponse }) => DateToFormattedString(params.row.created_time) },
            { field: "dns_names", sortable: false, headerName: t('DNS names'), type: "string", valueGetter: (_, row: AgreementResponse) => [...new Set(row.service?.endpoints?.map(endpoint => endpoint.dns_name + "." + endpoint.dns_suffix?.dns_suffix + "." + getEnvironment().REACT_APP_DSDN_DOMAIN))], flex: 2 },
            { field: "service_ips", sortable: false, headerName: t('Service IP'), type: "string", valueGetter: (_, row: AgreementResponse) => [...new Set(row.service?.endpoints?.map(endpoint => endpoint.ip))], flex: 2 },
            { field: "client_ips", sortable: false, headerName: t('Client IP'), type: "string", valueGetter: (_, row: AgreementResponse) => row.client_ip_addresses, flex: 2 },
            { field: "provision_status", sortable: true, headerName: t('Provision status') + " v4", type: "string", valueGetter: (status) => t(status ?? ""), flex: 2 },
            { field: "provision_time", sortable: true, headerName: t('Provision time'), type: "dateTime", valueGetter: (provisionTime) => provisionTime && new Date(provisionTime), renderCell: (params: { row: AgreementResponse }) => DateToFormattedString(params.row.provision_time), flex: 3 },
            { field: "criticality_score",   sortable: true,   minWidth: 100,   headerName: t('Criticality score'),   valueGetter: (_, row: AgreementResponse) => row.service?.criticality_score,  renderCell: (params: {row: AgreementResponse}) => <CriticalityScoreBadge  ressource={ params.row.service as ServiceBase} />,   type: "number",   flex: 1.5 },

        ];

        const buttonsView: GridColDef[] = [
            {
                field: 'events', sortable: false, hideable: false, type: "string", valueGetter: () => "", headerName: t('Actions'), minWidth: 220, renderCell: (params: any) => {
                    const agreement: AgreementResponse = getCurrentAgreement(agreements, params)
                    return (
                        <>
                            {!props.simpleView ?
                                <>
                                    <AgreementInfoButton
                                        key={JSON.stringify(uuid)}
                                        openFromStart={agreement.uuid == clickedRow?.uuid}
                                        onClose={() => setClickedRow(undefined)}
                                        agreement={agreement} />
                                    <Can I={PermissionEnum.AUDIT_READ} this={agreement}>
                                        <OpenInNewTabButton tooltipText='Events' icon={<History/>} url={`/events?entity_uuid=${agreement.uuid}`} />
                                    </Can>
                                    <OpenInNewTabButton url={`/agreements/${agreement.uuid}`} />
                                </> :
                                <>
                                    {deleteAgreementButton(agreement)}
                                    <OpenInNewTabButton url={`/agreements/${agreement.uuid}`} />
                                </>
                            }
                        </>
                    )
                }
            }
        ]

        const simpleList = simpleView;
        const advancedList = advancedView;
        const listToShow = props.simpleView ? simpleList : simpleList.concat(advancedList);
        return listToShow.concat(buttonsView);
    }

    return (
        <DatagridWrapper
            dataGridId={props.dataGridId ?? DataGridId.AGREEMENTS}
            defaultPagesize={props.defaultPagesize}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            loading={currentResult.isLoading}
            rows={currentResult.data}
            columns={getColumns(currentResult.data)}
            initialSortModel={[{ field: 'created_time', sort: 'desc' }]}
            initialColumnVisibilityModel={{
                dns_names: false,
                service_ips: false,
                client_ips: false,

            }}
        />
    )
}

