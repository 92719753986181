
import getEnvironment from '../../env'
import { RequestMutationWrapper } from '../../models/API/RequestMutationWrapper'
import { CPE } from '../../models/CPE'
import HandleQuery from '../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../redux/GetManyPackage'
import handleResponse from '../../redux/handleResponse'
import { portalApiSlice } from '../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const CPEApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCPEsByHostingSiteId: builder.query<CPE[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/hosting-sites/${getManyPackage.uuid}/cpes`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "CPEs could not be fetched" }),
      }),
      transformResponse: (a: CPE[], b, c) => transformMultipleResponseToRightType(CPE, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'cpes',
        ...result.map(({ uuid }) => ({ type: 'cpes', uuid: uuid }) as const)
      ]
    }),
    updateCPE: builder.mutation<CPE, RequestMutationWrapper<CPE>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/cpes/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "CPE was updated!", toastErrorText: "CPE could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      transformResponse: (a: CPE, b, c) => transformSingleResponseToRightType(CPE, a, b, c),
      invalidatesTags: ['cpes', 'hosting-sites', 'ipinfo'],
    }),
    addCPE: builder.mutation<CPE, RequestMutationWrapper<CPE>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/hosting-sites/${request.parentId}/cpes`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "CPE was created", toastErrorText: "CPE could not be created" }),
        method: 'POST',
        body: request.data,
      }),
      transformResponse: (a: CPE, b, c) => transformSingleResponseToRightType(CPE, a, b, c),

      invalidatesTags: ['cpes', 'hosting-sites', 'ipinfo'],
    }),
    deleteCPE: builder.mutation<void, RequestMutationWrapper<CPE>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/cpes/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "CPE was deleted", toastErrorText: "CPE could not be deleted" }),
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'cpes', uuid: args.data.uuid }]
    }),
  }),
})


export const { useAddCPEMutation, useDeleteCPEMutation, useGetCPEsByHostingSiteIdQuery, useUpdateCPEMutation } = CPEApiSlice
















