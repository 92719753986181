import InfoIcon from '@mui/icons-material/Info'
import { ButtonGroup, Stack, Tab, Typography } from "@mui/material"
import React, { useContext, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useParams } from 'react-router-dom'
import { ConfirmationButton } from '../../../components/ConfirmationButton'
import DrawerButton from "../../../components/DrawerButton"
import { EntityMetaDataComponent } from "../../../components/EntityMetaDataComponent"
import { CustomTabs } from '../../../components/Forms/CustomTabs'
import UseUrlParam from '../../../components/Logic/UseUrlProps'
import RessourceMenu from '../../../components/RessourceMenu'
import TabPanel from '../../../components/TabPanel'
import TooltipComponent from '../../../components/TooltipComponent'
import Loading from '../../../components/loading'
import getEnvironment from '../../../env'
import { PaginationQueryPackage } from '../../../models/API/QueryParams/PaginationQueryPackage'
import { HostingsiteResponse } from "../../../models/Hostingsite"
import { ApprovalStatus } from '../../../models/enums/ApprovalStatus'
import { AgreementList } from '../../AgreementApi/agreementList'
import { useDeleteAgreementMutation, useForceProvisionForHostingSiteMutation, useGetAgreementsByHostingSiteIdQuery } from "../../AgreementApi/redux/agreementsApiSlice"
import { useGetClientsByHostingSiteIdQuery } from '../../ClientApi/redux/clientsApiSlice'
import { PermissionEnum } from '../../Permission/PermissionEnum'
import { useGetServicesByHostingSiteIdQuery } from '../../ServiceApi/redux/serviceApiSlice'
import { AbilityContext, Can } from '../../UserApi/logic/Can'
import { MoveHostingSite } from '../MoveHostingSite'
import { useDeleteHostingsiteMutation, useGetHostingsiteByIdQuery } from '../hostingsitesApiSlice'
import DNSTab from './tabs/DNSTab'
import InfoTab from './tabs/InfoTab'
import SubnetTab from './tabs/SubnetTab'
import { DataGridId } from '../../../components/DatagridWrapper'
import CPETab from './tabs/CPETab'
import { Sync } from '@mui/icons-material'
import { HostingsiteBadge } from '../../../components/Badges/HostingsiteBadge'
import { MoveSubnet } from '../MoveSubnet'

interface HostingSiteInfoButtonProps {
    hostingSite: HostingsiteResponse
    openFromStart?: boolean
    onClose?: () => void
}

interface HostingSiteInfoDialogProps {
    hostingSiteUuid?: string
    afterDeleteAction: () => void
}

export const HostingSiteInfoButton = (props: HostingSiteInfoButtonProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <InfoIcon />
                </TooltipComponent>
            } footerContent={
                <EntityMetaDataComponent entity_uuid={props.hostingSite.uuid} />
            } >
            <HostingSiteInfoContent hostingSiteUuid={props.hostingSite.uuid} afterDeleteAction={() => setOpen(false)} />
        </DrawerButton>
    )
}

export const HostingSiteInfoContent = (props: HostingSiteInfoDialogProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["hostingSiteUuid"] ?? "" : "";

    const hostingSiteUuid = (props as HostingSiteInfoDialogProps).hostingSiteUuid ?? urlPropsUuid;

    const { data: agreementsData } = useGetAgreementsByHostingSiteIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: hostingSiteUuid,
        requestData: [ApprovalStatus.APPROVED]
    })
    const { data: clientsData } = useGetClientsByHostingSiteIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: hostingSiteUuid
    })
    const { data: servicesData } = useGetServicesByHostingSiteIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: hostingSiteUuid
    })

    const { data: hostingSite } = useGetHostingsiteByIdQuery(hostingSiteUuid);

    const { t } = useTranslation();
    const ability = useContext(AbilityContext);

    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const deleteAgreementTrigger = useDeleteAgreementMutation()[0];

    const forceProvisionTrigger = useForceProvisionForHostingSiteMutation()[0];

    if (hostingSite == undefined || agreementsData == undefined || clientsData == undefined || servicesData == undefined) {
        return <Loading />
    }

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab hostingSite={hostingSite} {...props} /></TabPanel>)

    if (ability.can(PermissionEnum.HOSTING_SITE_READ, hostingSite)) {
        labels.push(t("CPE") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><CPETab hostingSite={hostingSite} {...props} /></TabPanel>)
    }

    if (ability.can(PermissionEnum.HOSTING_SITE_READ, hostingSite)) {
        labels.push(t("Subnets") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><SubnetTab hostingSite={hostingSite} {...props} /></TabPanel>)
    }

    if (ability.can(PermissionEnum.HOSTING_SITE_READ, hostingSite)) {
        labels.push(t("DNS") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><DNSTab hostingSite={hostingSite} {...props} /></TabPanel>)
    }

    const hostingSiteHasActiveAgreements: boolean = agreementsData.length > 0
    const numberOfClients: number = clientsData.length;
    const numberOfServices: number = servicesData.length;
    const serviceSideAgreements = agreementsData.filter(agreement => agreement.service?.hosting_site_uuid == hostingSiteUuid)
    const serviceSideAgreementsWithNoForceProvision = serviceSideAgreements.filter(agreement => agreement.force_provision === false)
    const allServiceSideAgreementsHaveForceProvision = serviceSideAgreementsWithNoForceProvision.length === 0 && serviceSideAgreements.length > 0
    const isV4HostingSite = hostingSite.cpes != undefined &&
        hostingSite.cpes.filter(cpe => cpe.sdn_v4_id != undefined && cpe.sdn_v4_id.length > 0).length > 0
    console.log(serviceSideAgreements)
    return (
        <>
            <CustomTabs
                value={value}
                onChange={handleChange}
                rightMenu={
                    <RessourceMenu
                        isAllowedToDelete={(data) => ability.can(PermissionEnum.HOSTING_SITE_WRITE, data)}
                        nameOfRessource="hostingsite"
                        primaryButtonText={t("Hosting site") + ": " + hostingSite.name}
                        idToFetch={hostingSite.uuid}
                        deleteMutation={useDeleteHostingsiteMutation}
                        getByIdAction={useGetHostingsiteByIdQuery}
                        deleteButtonConfirmationText={t(`Are you sure you wish to delete this hostingsite?`, { "numberOfClients": numberOfClients, "numberOfServices": numberOfServices })}
                        deleteDialogMaxWidth="lg"
                        deleteButtonContent={!hostingSiteHasActiveAgreements ? undefined :
                            <Stack spacing={2}>
                                <Typography>{t("The hosting site has active agreements, that needs to be terminated in order to delete hosting site")}</Typography>
                                <AgreementList dataGridId={DataGridId.AGREEMENTS_SIMPLE} statusList={[ApprovalStatus.APPROVED]} simpleView={true} idToFetch={hostingSite.uuid} getAgreements={useGetAgreementsByHostingSiteIdQuery}></AgreementList>
                                <ButtonGroup>
                                    <ConfirmationButton
                                        maxWidth="lg"
                                        action={async () => {
                                            const promises = agreementsData?.map(async (agreement) => deleteAgreementTrigger({ data: agreement }));
                                            if (promises == undefined)
                                                return;

                                            return await Promise.all(promises) as unknown as Promise<void>;
                                        }}
                                        title={t("Delete all agreements")}
                                        buttonText={t("Delete all agreements")}>
                                        <>
                                            <Typography>{t("Are you sure you wish to delete all agreements on this hostingsite?", { "numberOfAgreements": agreementsData?.length })}</Typography>
                                            <Typography fontWeight="bold">{t("You will not be able to undo this action")}</Typography>
                                        </>
                                    </ConfirmationButton>
                                </ButtonGroup>
                            </Stack>
                        }
                        afterDeleteAction={(props as HostingSiteInfoDialogProps).afterDeleteAction} >
                        {getEnvironment().GET_SUPPORT_MOVE_HOSTING_SITE() &&
                            <Can I={PermissionEnum.HOSTING_SITE_WRITE} this={hostingSite} >
                                <MoveHostingSite hostingSite={hostingSite} />
                            </Can>
                        }


                        <Can I={PermissionEnum.GOD} this={hostingSite} >
                            <ConfirmationButton
                                asMenuItem
                                title={t("Force provision")}
                                buttonText={<><Sync /> {t("Force provision")}</>}
                                disabled={!isV4HostingSite || serviceSideAgreements.length == 0 || allServiceSideAgreementsHaveForceProvision}
                                tooltip={!isV4HostingSite ? t("Not an SDN V4 hosting site")
                                    : serviceSideAgreements.length == 0 ? t("No agreements")
                                        : allServiceSideAgreementsHaveForceProvision ? t("Provision in progress") : undefined}
                                action={async () => {
                                    await forceProvisionTrigger(hostingSite.uuid).unwrap()
                                }}
                            >
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography>{t("Hosting site")}:</Typography>
                                        <HostingsiteBadge ressource={hostingSite} />
                                    </Stack>
                                    <Typography>{t("Are you sure you wish to force provision of all agreements on this hosting site?", { "numberOfAgreements": serviceSideAgreements.length })}</Typography>
                                </Stack>
                            </ConfirmationButton>
                        </Can>

                        <Can I={PermissionEnum.GOD} this={hostingSite} >
                            <MoveSubnet hostingSite={hostingSite} />
                        </Can>
                    </RessourceMenu>
                }>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}