import { Box, CssBaseline } from '@mui/material';
import AutoBreadcrump from './AutoBreadcrumb';
import { SideMenu } from './SideMenu';
import { TopBar } from './Topbar';
import MuiXLicense from '../../MuiXLicense';

type LayoutProps = {
    children: JSX.Element
}


const Layout = (props: LayoutProps) => {

    const drawerWidth = 280;
    const contentWidth = "calc(100% - " + drawerWidth + "px)";

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <TopBar drawerWidth={drawerWidth}></TopBar>
                <SideMenu drawerWidth={drawerWidth}></SideMenu>
                <Box
                    component="main"
                    sx={{ marginTop: 10, width: contentWidth, bgcolor: 'background.default', p: 3 }}
                >
                    <AutoBreadcrump />
                    {props.children}
                    <MuiXLicense />
                </Box>
            </Box>
        </>
    )
}

export default Layout;