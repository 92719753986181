import BaseModel from "./BaseModels/BaseModel"
import OrganisationResponse from "./Organisation"
import Portalias from "./Portalias"

import { AttachContactRequest } from "./Contact"
import { CriticalityEnum } from "./enums/CriticalityEnum"
import { PropagationEnum } from "./enums/PropagationEnum"
import { ServiceCategoryEnum } from "./enums/ServiceCategoryEnum"
import HostingsiteBase, { HostingsiteResponse } from "./Hostingsite"
import { ServiceTagResponse } from "./ServiceTag"
import { Subnet } from "./Subnet"
import { UserResponse } from "./User"
import { DNSSuffix } from "./DNSSuffix"

export class ServiceBase extends BaseModel {
    name?: string
    servicetag?: ServiceTagResponse;
    service_categories?: ServiceCategoryEnum[]
    criticality_score?: number;
    description?: string
    internal_description?: string
    no_encryption_reason?: string
    requires_approval = false
    service_window_rules?: string
    sla?: number
    sla_description?: string
    uses_encryption?: boolean
    general_contact_information?: string
}
export default class ServiceRequest extends ServiceBase {
    criticality?: CriticalityEnum
    propagation?: PropagationEnum
    hosting_site?: HostingsiteBase;
    organisation?: OrganisationResponse;
    monitoring: ServiceMonitoring = new ServiceMonitoring()
}

export class CreateServiceRequest extends ServiceRequest {
    endpoints?: ServiceEndpoint[]
    approvers?: UserResponse[]
    contacts: AttachContactRequest[] = []
}

export class MoveServiceRequest {
    owner_organisation?: OrganisationResponse;
    service?: ServiceResponse;
    hosting_site?: HostingsiteBase;
    servicetag?: ServiceTagResponse;
    endpoints?: ServiceEndpoint[]
}

export class ServiceBaseResponse extends ServiceBase {
    short_id?: number
}

export class ServiceResponse extends ServiceBaseResponse {
    owner_organisation?: OrganisationResponse
    active_agreements?: number
    endpoints?: ServiceEndpoint[] = []
    hosting_site?: HostingsiteResponse;
    hosting_site_uuid?: string
}

export class ServiceResponseDetails extends ServiceBaseResponse {
    hosting_site?: HostingsiteResponse;
    monitoring: ServiceMonitoring = new ServiceMonitoring()
}

export class ServiceEndpoint extends BaseModel {
    subnet?: Subnet
    ip?: string
    dns_name?: string
    dns_suffix?: DNSSuffix
    ports: Portalias[] = []
    //importeness? : ServiceImportness //Lets wait with this one

}



export class ServiceMonitoring extends BaseModel {
    icmp_ping?: boolean
    tcp_ping?: boolean
    http_ping?: boolean
    http_url?: string
    tcp_portnr?: number

    alarming?: boolean
    email?: string

}

export class ServiceApproverRequest extends BaseModel {
    approver?: UserResponse
}