import { DriveFileMove } from '@mui/icons-material';
import { useState } from "react";
import * as Yup from 'yup';
import { ConfirmationButton } from '../../components/ConfirmationButton';
import { normaliseFieldrenders } from "../../components/CreateButtonProps";
import EasyForm from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { FormType } from "../../components/Forms/EasyFormMany";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { TextFieldRender } from '../../components/Forms/Renders/TextFieldRender';
import Loading from '../../components/loading';
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { HostingsiteResponse } from "../../models/Hostingsite";
import MoveSubnetRequest from '../../models/MoveSubnetRequest';
import { Subnet } from '../../models/Subnet';
import { useGetSubnetsByHostingsiteIdQuery, useMoveSubnetMutation } from '../OrganisationApi/redux/subnetApiSlice';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface moveSubnetProps {
    hostingSite: HostingsiteResponse
}

export const MoveSubnet = (props: moveSubnetProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const { data: subnets } = useGetSubnetsByHostingsiteIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.hostingSite.uuid
    });

    if (subnets == undefined) {
        return <Loading />
    }

    const getMoveSubnetRows = () => {
        return [
            new AutocompleteFieldRender({
                displayText: "Subnet",
                fieldName: "",
                getOptionLabelFunc: (option: Subnet) => option.subnet ?? "",
                renderOption: (_props, option) => option.subnet,
                nestedfieldName: "subnet",
                options: subnets ?? [],
                uniqueId: crypto.randomUUID()
            }),
            new TextFieldRender({
                displayText: t("Hosting site name"),
                fieldName: "hosting_site_name",
                type: "text",
                uniqueId: crypto.randomUUID()
            }),
            new TextFieldRender({
                displayText: t("Hosting site address"),
                fieldName: "hosting_site_address",
                type: "text",
                uniqueId: crypto.randomUUID()
            }),
        ];
    }

    const schema = Yup.object().shape({
        subnet: Yup.object().required(t("Required")),
        hosting_site_name: Yup.string().required(t("Required")),
        hosting_site_address: Yup.string().required(t("Required")),
    });

    return (

        <ConfirmationButton
            childrenOpen={open}
            setChildrenOpen={setOpen}
            asMenuItem
            title={t("Move subnet")}
            buttonText={<><DriveFileMove />{t("Move subnet")}</>}
            key={"moveSubnet"}
            showButtons={false}
            fullWidth
            maxWidth="md"
        >
            <EasyForm
                isAllowedToEdit={() => true}
                editModeAlways={true}
                arrayPath=""
                ressourceOwnerId={props.hostingSite.owner_organisation?.uuid}
                SignupSchema={schema}
                initialValue={new MoveSubnetRequest()}
                columns={[
                    new EasyFormColumn("", "", normaliseFieldrenders(getMoveSubnetRows()))
                ]}
                formType={FormType.INFORMATION}
                getFieldRows={() => []}
                saveMutation={useMoveSubnetMutation}
                createNew={() => { throw new Error("Dont create new here") }}
                afterSaveAction={() => {
                    setOpen(false)
                }}
                extraButtons={<Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>}
            />
        </ConfirmationButton>
    );
}