class AbstractEnv {
    REACT_APP_MUI_LICENSE_KEY =""
    REACT_APP_API_BASEURL = ""
    REACT_APP_API_MOCKBASEURL = ""
    REACT_APP_NODE_ENV = ""
    REACT_APP_DSDN_DOMAIN = ""

    //Featureflags
    REACT_APP_SUPPORT_IPV6 = ""
    REACT_APP_SUPPORT_MOVE_HOSTING_SITE = ""

    //Documentation link
    REACT_APP_SDN_DOCUMENTATION_LINK = ""

    //Accessibility statement link
    REACT_APP_SDN_ACCESSIBILITY_LINK = ""

    //Keycloak
    REACT_APP_KEYCLOAK_URL = ""
    REACT_APP_KEYCLOAK_REALM = ""
    REACT_APP_KEYCLOAK_CLIENTID = ""
    REACT_APP_INACTIVITY_MAX_MINUTES = ""

    GET_SUPPORT_IPV6(): boolean {
        return this.REACT_APP_SUPPORT_IPV6 == "true" ? true : false;
    }

    GET_SUPPORT_MOVE_HOSTING_SITE(): boolean {
        return this.REACT_APP_SUPPORT_MOVE_HOSTING_SITE == "true" ? true : false;
    }
    GET_INACTIVITY_MAX_MINUTES() : number {
        return parseInt(this.REACT_APP_INACTIVITY_MAX_MINUTES ?? "1") 
    }
}

export default function getEnvironment(): AbstractEnv {
    const env = (window as any)._jsenv || process.env;
    return Object.assign(new AbstractEnv(), JSON.parse(JSON.stringify(env))); 
}