import { Box, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import * as Yup from 'yup';
import EasyForm, { FormType } from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { CheckboxFieldRender } from "../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender";

import { AddCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import DrawerButton from "../../components/DrawerButton";
import TooltipComponent from "../../components/TooltipComponent";
import { ContactLabel, LabelContextEnum } from "../../models/ContactLabel";
import { PermissionEnum } from "../Permission/PermissionEnum";
import { AbilityContext, Can } from "../UserApi/logic/Can";
import { useAddContactLabelMutation } from "./redux/contactLabelApiSlice";

export const CreateContactlabelButton = () => {
    const [open, setOpen] = useState<boolean>(false)
    const { t } = useTranslation()
    return (
        <Can I={PermissionEnum.GOD} a={ContactLabel} >
            <TooltipComponent title={t("Create contactlabel") + ""}>
                <span>
                    <DrawerButton open={open} setOpen={setOpen} buttonContent={
                        <AddCircle />
                    } >
                        <CreateContactlabelButtonContent afterSaveAction={() => { setOpen(false); }} />
                    </DrawerButton>
                </span>
            </TooltipComponent>
        </Can >
    )
}

export interface CreateContactlabelButtonContentProps {
    afterSaveAction: () => void
}
export const CreateContactlabelButtonContent = (props: CreateContactlabelButtonContentProps) => {

    const { t } = useTranslation();

    const SignupSchema = Yup.object().shape({
        label: Yup.string().required(),
        label_contexts: Yup.array().default([]).min(1, t("You have to select at least one context")),
        required: Yup.boolean().default(false)

    });

    const ability = useContext(AbilityContext);
    const contactlabel = new ContactLabel()
    contactlabel.required = false;
    const [initialContact] = useState(contactlabel);
    return (
        <>

            <Box>
                <Grid container justifyContent="center">

                    <Grid item xs={6}>
                        <Typography variant="h6">{t("Create contactlabel") + ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>

                        <EasyForm
                            isAllowedToEdit={(contactlabel) => ability.can(PermissionEnum.GOD, contactlabel)}
                            editModeAlways={true}
                            arrayPath=""
                            SignupSchema={SignupSchema}
                            columns={[
                                new EasyFormColumn("", "", [
                                    new TextFieldRender({
                                        displayText: "Label",
                                        fieldName: "label",
                                        uniqueId: "no_id",
                                        type: "text"
                                    }),
                                    new TextFieldRender({
                                        displayText: "Comment",
                                        fieldName: "comment",
                                        uniqueId: "no_id",
                                        type: "text"
                                    }),
                                    new CheckboxFieldRender({
                                        displayText: "Required",
                                        fieldName: "required",
                                        uniqueId: "no_id"
                                    }),
                                    new AutocompleteFieldRender({
                                        displayText: "Label contexts",
                                        fieldName: "label_contexts",
                                        getOptionLabelFunc: (option: LabelContextEnum) => option.toString() ?? "",
                                        options: Object.values(LabelContextEnum) ?? [],
                                        multipleSelect: true,
                                        uniqueId: "no_id"
                                    }),
                                ]),

                            ]}
                            formType={FormType.INFORMATION}
                            getFieldRows={() => []}
                            saveMutation={useAddContactLabelMutation}
                            afterSaveAction={props.afterSaveAction}
                            createNew={() => initialContact}
                        />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}