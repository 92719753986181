
import getEnvironment from '../../../env';
import AgreementRequest, { AgreementResponse } from '../../../models/Agreement';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import { ApprovalStatus } from '../../../models/enums/ApprovalStatus';
import { CreateResponse } from '../../../redux/CreateResponse';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;

function statusToQuery(statuses: ApprovalStatus[] | undefined) {
  if (statuses == undefined || statuses.length == 0)
    return ""

  let result = "?"
  result += statuses.map(status => "status=" + status.toUpperCase()).join("&")
  return result;
}

export const agreementsApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAgreements: builder.query<AgreementResponse[], GetManyPackage<ApprovalStatus[]>>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/agreements${statusToQuery(pack.requestData)}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Agreements could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: AgreementResponse[], b, c) => transformMultipleResponseToRightType(AgreementResponse, a, b, c),
      providesTags: ['agreements']
    }),
    getAgreementsByServiceId: builder.query<AgreementResponse[], GetManyPackage<ApprovalStatus[]>>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/services/${pack.uuid}/agreements${statusToQuery(pack.requestData)}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Agreements for service could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: AgreementResponse[], b, c) => transformMultipleResponseToRightType(AgreementResponse, a, b, c),
      providesTags: ['agreements']
    }),
    getAgreementsByClientId: builder.query<AgreementResponse[], GetManyPackage<ApprovalStatus[]>>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/clients/${pack.uuid}/agreements${statusToQuery(pack.requestData)}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Agreements for client could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: AgreementResponse[], b, c) => transformMultipleResponseToRightType(AgreementResponse, a, b, c),
      providesTags: ['agreements']
    }),
    getAgreementsByOrganisationId: builder.query<AgreementResponse[], GetManyPackage<ApprovalStatus[]>>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/agreements${statusToQuery(pack.requestData)}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Agreements for organistation could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: AgreementResponse[], b, c) => transformMultipleResponseToRightType(AgreementResponse, a, b, c),
      providesTags: ['agreements']
    }),
    getAgreementsByOrganisationIdAndServiceApprover: builder.query<AgreementResponse[], GetManyPackage<ApprovalStatus[]>>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/service-approver/agreements${statusToQuery(pack.requestData)}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Agreements for organisation and service approver could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: AgreementRequest[], b, c) => transformMultipleResponseToRightType(AgreementRequest, a, b, c),
      providesTags: ['agreements']
    }),
    getAgreementsByHostingSiteId: builder.query<AgreementResponse[], GetManyPackage<ApprovalStatus[]>>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/hosting-sites/${pack.uuid}/agreements${statusToQuery(pack.requestData)}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Agreements for hosting site could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: AgreementResponse[], b, c) => transformMultipleResponseToRightType(AgreementResponse, a, b, c),
      providesTags: ['agreements']
    }),
    getAgreementById: builder.query<AgreementResponse, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/agreements/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Agreement could not be fetched" }),
      }),
      transformResponse: (a: AgreementResponse, b, c) => transformSingleResponseToRightType(AgreementResponse, a, b, c),
      providesTags: ['agreement']
    }),
    updateAgreement: builder.mutation<AgreementResponse, RequestMutationWrapper<AgreementRequest>>({
      query: (agreement) => HandleQuery({
        url: `${baseurl}/agreements/${agreement.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Agreement was updated!", toastErrorText: "Agreement could not be updated" }),
        method: "PATCH",
        body: {
          criticality: agreement.data.criticality,
          description: agreement.data.description,
        }
      }),
      transformResponse: (a: AgreementResponse, b, c) => transformSingleResponseToRightType(AgreementResponse, a, b, c),
      invalidatesTags: ['agreements', 'agreement', 'services'],
    }),
    addAgreementRequest: builder.mutation<CreateResponse, RequestMutationWrapper<AgreementRequest>>({
      query: (agreement) => HandleQuery({
        url: `${baseurl}/agreements`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Agreement request was created!", toastErrorText: "Agreement request could not be created" }),
        method: 'POST',
        body: {
          criticality: agreement.data.criticality,
          description: agreement.data.description,
          client_uuids: agreement.data.clients?.map(client => client.uuid),
          service_uuids: agreement.data.services?.map(service => service.uuid)
        },
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),
      invalidatesTags: ['agreements', 'services'],
    }),
    addApproval: builder.mutation<AgreementResponse, RequestMutationWrapper<AgreementRequest>>({
      query: (agreement) => HandleQuery({
        url: `${baseurl}/agreements/${agreement.data.uuid}/approve`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Request was approved!", toastErrorText: "Request could not be approved" }),
        method: 'POST',
        body: {
          comment: agreement.data.approval_comment
        },
      }),
      transformResponse: (a: AgreementResponse, b, c) => transformSingleResponseToRightType(AgreementResponse, a, b, c),
      invalidatesTags: ['agreements', 'services'],
    }),
    addDenial: builder.mutation<AgreementResponse, RequestMutationWrapper<AgreementRequest>>({
      query: (agreement) => HandleQuery({
        url: `${baseurl}/agreements/${agreement.data.uuid}/deny`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Request was denied!", toastErrorText: "Request could not be denied" }),
        method: 'POST',
        body: {
          comment: agreement.data.approval_comment
        }
      }),
      transformResponse: (a: AgreementResponse, b, c) => transformSingleResponseToRightType(AgreementResponse, a, b, c),

      invalidatesTags: ['agreements', 'services'],
    }),
    deleteAgreement: builder.mutation<void, RequestMutationWrapper<AgreementRequest>>({
      query: (agreement) => HandleQuery({
        url: `${baseurl}/agreements/${agreement.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Agreement was deleted", toastErrorText: "Agreement could not be deleted" }),
      }),

      invalidatesTags: ['agreements', 'services'],
    }),
    forceProvisionForHostingSite: builder.mutation<void, string>({
      query: (hostingSiteUuid) => HandleQuery({
        url: `${baseurl}/hosting-sites/${hostingSiteUuid}/agreements/force-provision`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Agreements on hosting site marked for provision", toastErrorText: "Agreements could not be marked for provision", toastWithResult: true }),
        method: 'POST'
      }),
      invalidatesTags: ['agreements'],
    }),
  }),
})


export const { useGetAllAgreementsQuery, useAddDenialMutation, useAddApprovalMutation, useGetAgreementsByClientIdQuery, useGetAgreementByIdQuery, useDeleteAgreementMutation, useUpdateAgreementMutation, useGetAgreementsByOrganisationIdQuery, useGetAgreementsByOrganisationIdAndServiceApproverQuery, useGetAgreementsByHostingSiteIdQuery, useGetAgreementsByServiceIdQuery, useAddAgreementRequestMutation, useForceProvisionForHostingSiteMutation } = agreementsApiSlice
















