import BaseModel from "./BaseModels/BaseModel"
import { CPE } from "./CPE"
import { DNSSuffix } from "./DNSSuffix"
import OrganisationResponse from "./Organisation"
import { Subnet } from "./Subnet"


export default class HostingsiteBase extends BaseModel {
    name?: string
    address?: string
    administrating_organisation?: OrganisationResponse
    owner_organisation?: OrganisationResponse
}

export class CreateHostingSiteRequest extends BaseModel {
    name?: string
    address?: string
    subnets: Subnet[] = []
    dns_suffixes: DNSSuffix[] = []
    cpes: CPE[] = []
    organisation?: OrganisationResponse
}

export class HostingsiteResponse extends HostingsiteBase {
    cpes?: CPE[] = []
}