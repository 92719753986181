import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext, useState } from "react";
import * as Yup from 'yup';
import DrawerButton from "../../components/DrawerButton";
import EasyForm from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { FormType } from "../../components/Forms/EasyFormMany";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { CheckboxFieldRender } from "../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender";
import { ContactLabel, LabelContextEnum } from "../../models/ContactLabel";
import { PermissionEnum } from "../Permission/PermissionEnum";
import { AbilityContext, Can } from "../UserApi/logic/Can";
import { useUpdateContactLabelMutation } from "./redux/contactLabelApiSlice";

export interface EditContactlabelButtonProps {
    contactLabel: ContactLabel
}
export const EditContactlabelButton = (props: EditContactlabelButtonProps) => {
    const [open, setOpen] = useState<boolean>(false)

    return (

        <Can I={PermissionEnum.GOD} a={ContactLabel} >
            <DrawerButton variant="contained" open={open} setOpen={setOpen} buttonContent={t("Edit")} >
                <EditContactlabelButtonContent contactLabel={props.contactLabel} afterSaveAction={() => { setOpen(false); }} />
            </DrawerButton>
        </Can >
    )
}

export interface EditContactlabelButtonContentProps {
    afterSaveAction: () => void
    contactLabel: ContactLabel
}
export const EditContactlabelButtonContent = (props: EditContactlabelButtonContentProps) => {
    const SignupSchema = Yup.object().shape({
        label: Yup.string().required(),
        label_contexts: Yup.array().default([]).min(1,t("You have to select at least one context")),
        required: Yup.boolean().default(false)

    });

    const ability = useContext(AbilityContext);
    const [contact] = useState(props.contactLabel);
    return (
        <>
            <Box>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>
                        <Typography variant="h6">{t("Edit contactlabel") + ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>
                        <EasyForm
                            isAllowedToEdit={(contactlabel) => ability.can(PermissionEnum.GOD, contactlabel)}
                            editModeAlways={true}
                            arrayPath=""
                            SignupSchema={SignupSchema}
                            columns={[
                                new EasyFormColumn("", "", [
                                    new TextFieldRender({
                                        displayText: "Label",
                                        fieldName: "label",
                                        uniqueId: "no_id",
                                        type: "text"
                                    }),
                                    new TextFieldRender({
                                        displayText: "Comment",
                                        fieldName: "comment",
                                        uniqueId: "no_id",
                                        type: "text"
                                    }),
                                    new CheckboxFieldRender({
                                        displayText: "Required",
                                        fieldName: "required",
                                        uniqueId: "no_id"
                                    }),
                                    new AutocompleteFieldRender({
                                        displayText: "Label contexts",
                                        fieldName: "label_contexts",
                                        getOptionLabelFunc: (option: LabelContextEnum) => option.toString() ?? "",
                                        options: Object.values(LabelContextEnum) ?? [],
                                        multipleSelect: true,
                                        uniqueId: "no_id"
                                    }),
                                ]),

                            ]}
                            formType={FormType.INFORMATION}
                            getFieldRows={() => []}
                            saveMutation={useUpdateContactLabelMutation}
                            afterSaveAction={props.afterSaveAction}
                            createNew={() => contact}
                        />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}