import { FormControlLabel, Switch } from "@mui/material";
import { FieldArray, FormikProps } from "formik";
import { TFunction, useTranslation } from "react-i18next";
import * as Yup from 'yup';
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { FormType } from "../../../components/Forms/EasyFormMany";
import { EasyFormRow } from "../../../components/Forms/FormLayout";
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { DNSSuffix } from "../../../models/DNSSuffix";
import HostingsiteBase from "../../../models/Hostingsite";
import { CreateOrganisationRequest, OrganisationDNSSuffix } from "../../../models/Organisation";

export function getDnsSuffixStepValidation(allDnsSuffixes: DNSSuffix[], t: TFunction<"translation", undefined>, allowExistingDnsSuffixes: boolean) {
    const existingSuffixes = allDnsSuffixes.map(suffix => suffix.dns_suffix)

    return Yup.object().shape({
        dnsSuffixes: Yup.array().of(Yup.object().shape({
            hosting_site: Yup.object().required(t('Required')),
            dns_suffix: Yup.string()
                .required(t('Required'))
                .test("unique_suffix", t("DNS suffix is already in use at another hosting site"), suffix => allowExistingDnsSuffixes || !existingSuffixes.includes(suffix))
        })).test("unique_suffixes", t("No duplicates allowed"),
            list => !list || list.length === new Set(list.map(suffix => suffix.hosting_site?.uuid + suffix.dns_suffix)).size)
    })
}

export default function DnsSuffixStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }, allowExistingSuffixes: boolean, allowExistingSuffixesOnChange: (value: boolean) => void) {
    const { t } = useTranslation();
    function getRows(
        organisationRequest: CreateOrganisationRequest
    ): Array<FieldEditorRender[]> {
        const rows: Array<FieldEditorRender[]> = []
        organisationRequest.dnsSuffixes?.forEach((dnsSuffix) => {
            const newRow: FieldEditorRender[] = [
                new AutocompleteFieldRender({
                    displayText: "Hosting site",
                    fieldName: "dnsSuffixes",
                    nestedfieldName: "hosting_site",
                    getOptionLabelFunc: (option: HostingsiteBase) => option.name ?? "",
                    options: organisationRequest.hosting_sites,
                    renderNonChangeable: (content) => <>{content?.toString()}</>,
                    uniqueId: dnsSuffix.uuid,
                    showLabel: true,
                    columns: 12
                }),
                new TextFieldRender({
                    displayText: "DNS suffix",
                    showLabel: true,
                    fieldName: "dnsSuffixes",
                    nestedfieldName: "dns_suffix",
                    type: "text",
                    columns: true,
                    uniqueId: dnsSuffix.uuid
                })
            ]
            rows.push(newRow)
        })
        return rows;
    }

    return (
        <>
        <FieldArray
            name="dnsSuffixes"
            render={arrayHelpers => (
                <EasyFormRow
                    confirmDeletionText={t("Are you sure you wish to remove this dns suffix?")}
                    {...props.formikProps}
                    {...props.formPropsStuf}
                    editModeAlways={true}
                    editable={true}
                    isDeleteable={true}
                    saveMutation={() => { throw "Dont save plz" }}
                    formType={FormType.LIST}
                    createNew={() => new OrganisationDNSSuffix()}
                    editMode={true}
                    getFieldRows={(createOrganisationRequest) => getRows(createOrganisationRequest as CreateOrganisationRequest)}
                    columns={[
                        new EasyFormColumn("Dns Suffixes", "", [])
                    ]}
                    arrayHelpers={arrayHelpers} />
            )
            }
        />
        <FormControlLabel
            control={<Switch />}
            checked={allowExistingSuffixes}
            onChange={(_, checked) => allowExistingSuffixesOnChange(checked)}
            label={t("Allow existing DNS suffixes")}
            labelPlacement="start" />
        </>
    )
}